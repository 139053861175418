import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification } from 'src/interfaces/notifications';


interface NotificationsState {
    notification: Notification;
}

const initialState: NotificationsState = {
    notification: null,
};

const WSnotificationsSlice = createSlice({
    name: 'ws-notifications',
    initialState,
    reducers: {
        addNotification: (state, action: PayloadAction<Notification>) => {
            state.notification = action.payload;
        },
        removeNotification: (state, action: PayloadAction<Notification>) => {
            state.notification = action.payload;
        },
    },
});

export const { addNotification, removeNotification } = WSnotificationsSlice.actions;

export default WSnotificationsSlice.reducer;