import { Suspense, lazy, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Bulbs from './content/dashboards/Bulbs';
import { auth } from './config/firebase';
import { CircularProgress } from '@mui/material';
import Login from './content/Auth/login-page';
import Organisations from './content/dashboards/Organisations';

export const PrivateRoute = ({ element, ...rest }) => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (auth?.currentUser?.emailVerified) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [auth.currentUser]);


  return isLoading ? (
    <CircularProgress />
  ) : auth.currentUser === null ? (
    <Navigate to="/login" replace />
  ) : (
    element
  )
};

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Dashboards
const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));
const Messenger = Loader(lazy(() => import('src/content/applications/Messenger')));

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to="dashboards" replace />
  },
  {
    path: 'dashboards',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <Navigate to="orgs" replace />
      },
      {
        path: 'orgs',
        element: <Organisations />
      },
      {
        path: 'bulbs',
        element: <Bulbs />
      },
      {
        path: 'settings',
        element: <Bulbs />
      }
    ]
  },
  { path: '/login', element: <Login /> },
];

export default routes;