import { useNavigate, useRoutes } from 'react-router-dom';
import router from 'src/router';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import CRMChatBot from './components/crm/chat-bot';
import { useEffect, useState } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from './config/firebase';
import { useDispatch } from 'react-redux';
import { _clearUser } from './redux/reducers/Users/userSlice';
import LoadingScreen from './components/admin-transition';


function App() {
  const content = useRoutes(router);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isWorthy, setIsWorthy] = useState(false);

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();


  const dispatch = useDispatch();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, handleAuthStateChange);
    return () => unsubscribe();
  }, []);


  const handleSignOut = async () => {
    dispatch(_clearUser(null));
    await signOut(auth);
  };

  const handleAuthStateChange = async (user: any) => {
    if (user) {
      const idTokenResult = await user.getIdTokenResult();
      if (idTokenResult.claims?.role === 'superadmin') {
        handleLoadingComplete();

      }
    } else {
      await handleSignOut();
    }
    setLoading(false);
  };

  const handleLoadingComplete = async () => {
    setIsSuperAdmin(false);
    setIsWorthy(false);
    console.log('loading complete');
    navigate('/');
  }

  if (isWorthy) {
    return <LoadingScreen onComplete={handleLoadingComplete} duration={5000} />
  }


  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {content}
      </LocalizationProvider>

      {/* <CRMChatBot /> */}
    </ThemeProvider>
  );
}
export default App;
