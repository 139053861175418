// activitiesSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Home } from '../../../interfaces/homes.interface';
import { Bulb } from '../../../interfaces/bulbs.interface';

import { remove } from 'firebase/database';
import { api } from 'src/@rtk/queries';

// Define an initial state

const initialState = {
  macAddress: undefined,
  unassignedBulbs: [] as Bulb[],
  bulbs: [] as Bulb[],
  faultyBulbs: [] as Bulb[],
};

// Create a slice
const bulbSlice = createSlice({
  name: 'bulbSlice',
  initialState,
  reducers: {
    _registermac: (state, action) => {
      localStorage.setItem('mac', action.payload);
      return {
        ...initialState,
        macAddress: action.payload,
      };
    },
    _removeMac: (state, action) => {
      localStorage.removeItem('mac');
      return {
        ...initialState,
      };
    },
    _addHomes: (state, action: PayloadAction<any>) => {
      return state;
    },
    _clearHome: (state, action: PayloadAction<any>) => {
      return {
        ...initialState,
      };
    },

    _assignNextMasterBulb: (state, action: PayloadAction<{ roomId: string; nextMasterBulbId: string }>) => {
      const { roomId, nextMasterBulbId } = action.payload;
      const roomBulbs = state.bulbs.filter(bulb => bulb.roomId === roomId);
      const nextMasterBulbIndex = state.bulbs.findIndex(bulb => bulb.id === nextMasterBulbId);
      if (nextMasterBulbIndex !== -1) {
        state.bulbs[nextMasterBulbIndex].isMaster = true;
        roomBulbs.forEach(bulb => {
          if (bulb.id !== nextMasterBulbId) {
            bulb.isMaster = false;
          }
        });
      }
    },

    _alterBulbState: (
      state,
      action: PayloadAction<{
        bulb: Bulb;
        nextMasterBulbId: string;
        prevRoomId?: string;
      }>,
    ) => {
      const { bulb, nextMasterBulbId, prevRoomId } = action.payload;
      const { id, roomLabel, isMaster, roomId } = bulb;

      // Find the index of the bulb being updated
      const index = state.bulbs.findIndex(bulb => bulb.id === id);

      // Check if the new room already has other bulbs
      const roomBulbs = state.bulbs.filter(bulb => bulb.roomId === roomId);
      const prevroomBulbs = state.bulbs.filter(bulb => bulb.roomId === prevRoomId);
      const hasOtherBulbsInRoom = roomBulbs.length > 0;
      const hasOtherBulbsInPrevRoom = prevroomBulbs.length > 1;

      // If changing room ID and label
      if (roomId !== state.bulbs[index].roomId) {
        // Update room ID and label
        state.bulbs[index].roomId = bulb.roomId;
        state.bulbs[index].roomLabel = roomLabel;

        // Check if the new room already has a master bulb
        const newRoomHasMaster = roomBulbs.some(bulb => bulb.isMaster);

        // If the new room doesn't have a master, make this bulb the master
        // Otherwise, make it a slave
        state.bulbs[index].isMaster = !newRoomHasMaster;

        if (hasOtherBulbsInPrevRoom) {
          prevroomBulbs.forEach(otherBulb => {
            if (otherBulb.id === nextMasterBulbId) {
              otherBulb.isMaster = true;
            }
          });
        }
      } else {
        // If not changing room ID and label
        // If changing master/slave status
        if (state.bulbs[index].isMaster !== isMaster) {
          // If making this bulb a master
          if (isMaster) {
            // Set isMaster to true for this bulb
            state.bulbs[index].isMaster = true;

            // Change other bulbs in the same room to have isMaster false
            roomBulbs.forEach(bulb => {
              if (bulb.id !== id) {
                bulb.isMaster = false;
              }
            });
          } else {
            // If making this bulb a slave
            state.bulbs[index].isMaster = false;
          }
        }
      }

      // If the bulb is being moved out of the room and there's a next master bulb
      if (roomId !== state.bulbs[index].roomId && nextMasterBulbId && hasOtherBulbsInPrevRoom) {
        // Find the next master bulb and make it a master
        const nextMasterBulbIndex = state.bulbs.findIndex(bulb => bulb.id === nextMasterBulbId);
        if (nextMasterBulbIndex !== -1) {
          state.bulbs[nextMasterBulbIndex].isMaster = true;
        }
      }
    },

    _appendToUnassignedBulbs: (state, action: PayloadAction<Bulb[]>) => {
      state.unassignedBulbs = state.unassignedBulbs.concat(action.payload);
    },

    _addUnassignedBulb: (state, action: PayloadAction<Bulb[]>) => {
      state.unassignedBulbs = action.payload;
    },
    _addBulbsIntoHome: (state, action: PayloadAction<Bulb[]>) => {
      action.payload.forEach(newBulb => {
        const existingBulbIndex = state.bulbs.findIndex(bulb => bulb.id === newBulb.id);
        if (existingBulbIndex !== -1) {
          // If a bulb with the same ID already exists, replace it
          state.bulbs[existingBulbIndex] = newBulb;
        } else {
          // If a bulb with the same ID doesn't exist, add it to the state
          state.bulbs.push(newBulb);
        }
      });
    },
    _removeBulbsFromHome: (state, action: PayloadAction<{ homeId: string }>) => {
      state.bulbs = state.bulbs.filter(bulb => bulb.homeId !== action.payload.homeId);
    },
    _removeBulbFromHome: (state, action: PayloadAction<{ bulbId: string }>) => {
      state.bulbs = state.bulbs.filter(bulb => bulb.id !== action.payload.bulbId);
    },
    _removeUnassignedBulb: (state, action: PayloadAction<{ bulbId: string }>) => {
      console.log(action.payload.bulbId, 'action thlyi');
      state.unassignedBulbs = state.unassignedBulbs.filter(bulb => bulb.id !== action.payload.bulbId);
    },

    addFaultyBulb: (state, action: PayloadAction<Bulb>) => {
      state.faultyBulbs.push(action.payload);
    },
    removeFaultyBulb: (state, action: PayloadAction<Bulb>) => {
      state.faultyBulbs = state.faultyBulbs.filter(bulb => bulb.id !== action.payload.id);
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(api.endpoints.getAllUnassignedBulbs.matchFulfilled, (state, action) => {
        return {
          ...state,
          unassignedBulbs: action.payload.data,
        };
      })
      .addMatcher(api.endpoints.updateUser.matchFulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload.data,
        };
      });
  },
});

// Export actions and reducer
export const {
  _clearHome,
  _addHomes,
  _registermac,
  _removeMac,
  _addUnassignedBulb,
  _removeUnassignedBulb,
  _addBulbsIntoHome,
  _alterBulbState,
  _removeBulbsFromHome,
  _removeBulbFromHome,
  _appendToUnassignedBulbs,
  _assignNextMasterBulb,
} = bulbSlice.actions;
export default bulbSlice.reducer;
