import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface BulbSearch {
    bulbId: string;
    homeLabel: string;
    roomLabel: string;
}

interface BulbSearchState {
    bulbSearchList: BulbSearch[];
}

const initialState: BulbSearchState = {
    bulbSearchList: [],
};

const bulbSearchSlice = createSlice({
    name: 'bulbSearch',
    initialState,
    reducers: {
        addBulbSearch: (state, action: PayloadAction<BulbSearch[]>) => {
            state.bulbSearchList = action.payload;
        },
        removeBulbSearch: (state, action: PayloadAction<string>) => {
            state.bulbSearchList = state.bulbSearchList.filter(
                (bulbSearch) => bulbSearch.bulbId !== action.payload
            );
        },
    },
});

export const { addBulbSearch, removeBulbSearch } = bulbSearchSlice.actions;

export default bulbSearchSlice.reducer;