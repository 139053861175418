import React, { useMemo } from 'react';
import { useGetOrgsQuery } from 'src/@rtk/queries';
import {
  Typography,
  CircularProgress,
  Box,
  Paper,
  Grid,
  useTheme,
} from '@mui/material';
import { Error } from '@mui/icons-material';
import OrgCard from './org-card';

const Organisations: React.FC = () => {
  const {
    data: orgs,
    isLoading: isOrgsLoading,
    error: orgsError,
  } = useGetOrgsQuery(undefined);

  const theme = useTheme();

  const sortedOrgs = useMemo(() => {
    if (!orgs?.data) return [];
    return [...orgs.data].sort((a, b) => {
      // First, sort by faulty status
      if (a?.hasFaultyBulbs && !b?.hasFaultyBulbs) return -1;
      if (!a?.hasFaultyBulbs && b?.hasFaultyBulbs) return 1;

      // If both are faulty or both are not faulty, sort by fault count
      if (a?.hasFaultyBulbs && b?.hasFaultyBulbs) {
        return b?.faultyBulbCount - a?.faultyBulbCount; // Higher count first
      }

      // If neither is faulty, maintain original order
      return 0;
    });
  }, [orgs?.data]);

  if (isOrgsLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (orgsError) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Paper elevation={3} sx={{ p: 3, textAlign: 'center' }}>
          <Error color="error" sx={{ fontSize: 60, mb: 2 }} />
          <Typography variant="h6" color="error">
            Error loading organizations
          </Typography>
        </Paper>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        p: 3,
        height: `calc(100vh - ${theme.header.height})`,
        overflowY: 'auto',
        pb: 10
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ mb: 3 }}>
        Organizations Overview
      </Typography>
      <Grid container spacing={3}>
        {sortedOrgs.map((org) => (
          <Grid item xs={12} sm={6} md={4} key={org.id}>
            <OrgCard org={org} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Organisations;