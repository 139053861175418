// activitiesSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserState } from '../../types/user';

// Define an initial state
const initialState: UserState = {
  id: null,
  email: null,
  displayName: null,
  role: null,
  authToken: null,
  emailVerified: null,
  firstName: null,
  lastName: null,
  phone: null,
  communityId: null,
  token: null,
  fcmToken: null,
  pending: false,
  isCommunityAdmin: false,
  isMember: false,
  apiKey: null,
  loading: true,
  profilePictureLarge: '',
  profilePictureSmall: '',
  organisation: null,
  orgName: null,
  orgId: null
};

// Create a slice
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    _appendToUser: (state, action: PayloadAction<UserState>) => {
      localStorage.setItem(
        'userState',
        JSON.stringify({
          ...state,
          ...action.payload
        })
      );
      return {
        ...state,
        ...action.payload
      };
    },
    _setUser: (state, action: PayloadAction<UserState>) => {
      localStorage.setItem(
        'userState',
        JSON.stringify({
          ...state,
          ...action.payload
        })
      );

      let isMember,
        isAdmin = false;

      return {
        ...state,
        ...action.payload,
        isMember,
        isAdmin
      };
    },
    _setFcmToken: (state, action: PayloadAction<{ fcmToken: string }>) => {
      state.fcmToken = action.payload.fcmToken;
    },
    _clearFcmToken: (state, action: PayloadAction<undefined>) => {
      state.fcmToken = null;
    },
    _signOutInit: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    _clearUser: (state) => {
      localStorage.removeItem('userState');
      return {
        ...initialState,
        loading: false
      };
    }
  }
});

// Export actions and reducer
export const {
  _setUser,
  _clearUser,
  _appendToUser,
  _signOutInit,
  _setFcmToken,
  _clearFcmToken
} = userSlice.actions;
export default userSlice.reducer;
