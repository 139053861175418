import React, { useContext, useEffect } from 'react';

import {
  Box,
  alpha,
  Stack,
  lighten,
  Divider,
  IconButton,
  Tooltip,
  styled,
  useTheme,
  useMediaQuery
} from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import HeaderMenu from './Menu';


import heleosLogoWhiteoutRGB from 'src/assets/heleos-logo-whiteout-rgb.png';
import heleosLogo from '../../../assets/heleos.png';
import { Capacitor } from '@capacitor/core';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
 
  z-index: 6;
  backdrop-filter: blur(3px);
  position: relative;
  justify-content: space-between;
  width: 100vw;
  padding-inline: 20px;
`
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
      sx={{
        height: Capacitor.getPlatform() === 'ios' ? `calc(${theme.header.height} + env(safe-area-inset-top))` : theme.header.height,
        alignItems: Capacitor.getPlatform() === 'ios' ? 'flex-end' : 'center',
        boxShadow: isMobile || isTablet ? 'none' : theme.header.boxShadow,
        backgroundColor:
          isMobile || isTablet
            ? theme.colors.gradients.primary
            : theme.colors.alpha.trueWhite[100]
      }}
    >
      <Box
        component="span"
        sx={{
          display: { lg: 'none', xs: 'flex', md: 'none' },
          alignItems: 'center'
        }}
      >
        <Tooltip arrow title="Toggle Menu">
          <IconButton color="primary" onClick={toggleSidebar}>
            <MenuTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          ml={isMobile || isTablet ? '0px' : '0px'}
        >
          <img
            src={isMobile || isTablet ? heleosLogo : heleosLogo}
            style={{ height: '30px', marginRight: '10px', marginTop: '5px' }}
            alt="logo"
          />
        </Stack>
      </Box>
      <Box
        sx={{
          display: {
            xs: 'none',
            sm: 'none',
            lg: 'inline-block',
            md: 'inline-block'
          },
          alignItems: 'center',
          marginLeft: theme.spacing(2)
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <img
            src={heleosLogo}
            style={{ height: '30px', marginRight: '10px' }}
            alt="logo"
          />
        </Stack>
      </Box>
      <HeaderButtons />

    </HeaderWrapper>
  );
}

export default Header;