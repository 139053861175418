import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography, useTheme, Paper, alpha } from '@mui/material';
import { Bulb } from 'src/interfaces/bulbs.interface';
import BulbGlow from './glow';
import moment from 'moment';

const cssClassesBasedOnBulbState = ['#89BF6C', '#FFBF00', '#ec5c5c'];

export interface IBulbLiveViewProps {
    bulb: Bulb;
    timeThreshold: number;
    onSelectBulb: (bulb: any) => void;
}

const TickerText: React.FC<{ text: string; color: string }> = ({ text, color }) => {
    const textRef = useRef<HTMLDivElement>(null);
    const [isOverflowing, setIsOverflowing] = useState(false);

    useEffect(() => {
        if (textRef.current) {
            setIsOverflowing(textRef.current.scrollWidth > textRef.current.clientWidth);
        }
    }, [text]);

    return (
        <Typography
            ref={textRef}
            sx={{
                color: color,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                '&:hover': isOverflowing ? {
                    overflow: 'visible',
                    animation: isOverflowing ? 'ticker 5s linear infinite' : 'none',
                    '&::after': {
                        content: isOverflowing ? `" ${text}"` : 'none',
                    },
                } : {},
                '@keyframes ticker': {
                    '0%': { transform: 'translateX(0%)' },
                    '100%': { transform: 'translateX(-100%)' },
                },
            }}
        >
            {text}
        </Typography>
    );
};

export function BulbLiveView({ bulb, timeThreshold, onSelectBulb }: IBulbLiveViewProps) {
    const theme = useTheme();
    let requestColor = '#ffffff';

    const processedBulbData = React.useMemo(() => {
        let state = 0;
        let lastActive = null;

        const timestampSeconds = bulb.lastCommunicationTimestamp;
        if (timestampSeconds) {
            const currentTime = moment();
            const timestampMoment = moment.unix(timestampSeconds);
            const differenceSeconds = currentTime.diff(timestampMoment, 'seconds');
            const requestCount = bulb.latestRequestCount;



            if (requestCount > 0 && requestCount < 20) {
                requestColor = cssClassesBasedOnBulbState[2];
            }
            else if (requestCount >= 20 && requestCount < 40) {
                requestColor = cssClassesBasedOnBulbState[1];
            }
            else if (requestCount >= 40) {
                requestColor = cssClassesBasedOnBulbState[0];
            }

            if (bulb.isFaulty) {
                state = 2;
            }

            if (differenceSeconds < 60) {
                lastActive = `${differenceSeconds}s`;
            } else if (differenceSeconds < 3600) {
                lastActive = `${Math.floor(differenceSeconds / 60)}m`;
            } else if (differenceSeconds < 86400) {
                lastActive = `${Math.floor(differenceSeconds / 3600)}h`;
            } else if (differenceSeconds < 2592000) {
                lastActive = `${Math.floor(differenceSeconds / 86400)}d`;
            } else if (differenceSeconds < 31536000) {
                lastActive = `${Math.floor(differenceSeconds / 2592000)}mo`;
            } else {
                lastActive = `${Math.floor(differenceSeconds / 31536000)}y`;
            }
        } else {
            state = 2;
            lastActive = 'N/A';
        }

        return {
            ...bulb,
            lastActive,
            state,
        };
    }, [bulb, timeThreshold]);

    return (
        <Paper
            elevation={2}
            sx={{
                flex: 1,
                height: 130,
                position: 'relative',
                overflow: 'hidden',
                borderRadius: 2,
                cursor: 'pointer',
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: theme.shadows[6],
                },
                backgroundColor: processedBulbData.state === 2 ? theme.palette.error.light : theme.palette.background.paper,
            }}
            onClick={() => onSelectBulb(bulb)}
        >
            <Box px={1.5} py={2} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        mb: 1,
                    }}
                >
                    <BulbGlow
                        color={cssClassesBasedOnBulbState[processedBulbData.state]}
                        isPulsing={false}
                        iconSize="30px"
                    />
                    <div>
                        <Typography
                            variant="caption"
                            sx={{
                                backgroundColor: theme.palette.grey[200],
                                color: theme.palette.text.primary,
                                padding: '2px 6px',
                                borderRadius: 10,
                                fontWeight: 'bold',
                                fontSize: '0.7rem',
                                maxWidth: '50%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {processedBulbData.lastActive}
                        </Typography>
                        <Typography
                            variant="caption"
                            sx={{
                                backgroundColor: alpha(requestColor, 0.5),
                                color: theme.palette.text.primary,
                                padding: '2px 6px',
                                borderRadius: 10,
                                fontWeight: 'bold',
                                fontSize: '0.7rem',
                                maxWidth: '50%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                marginInline: '2px'
                            }}
                        >
                            {processedBulbData.latestRequestCount || 0}
                        </Typography>
                    </div>
                </Box>
                <TickerText
                    text={bulb.homeLabel}
                    color={processedBulbData.state === 2 ? 'white' : theme.palette.text.primary}
                />
                <TickerText
                    text={bulb.roomLabel}
                    color={processedBulbData.state === 2 ? 'white' : theme.palette.text.secondary}
                />
                {bulb.isMaster && (
                    <Typography
                        variant="caption"
                        sx={{
                            fontWeight: 'bold',
                            color: processedBulbData.state === 2 ? 'white' : theme.palette.primary.main,
                            fontSize: '0.7rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        Primary
                    </Typography>
                )}
            </Box>
        </Paper>
    );
}