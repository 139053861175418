import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserState } from 'src/interfaces/users';

interface MemberRetrieved {
  data: UserState;
  id: string;
}
const initialState: MembersState = {
  members: [],
  loading: false,
  error: null
};

interface MembersState {
  members: MemberRetrieved[];
  loading: boolean;
  error: string | null;
}

const membersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    fetchMembersStart(state) {
      state.loading = true;
      state.error = null;
    },
    setMembers(state, action: PayloadAction<MemberRetrieved[]>) {
      state.members = action.payload;
      state.loading = false;
      state.error = null;
    },
    removeMember(state, action: PayloadAction<string>) {
      state.members = state.members.filter(
        (member) => member.id !== action.payload
      );
    },
    fetchMembersFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    changeMemberRole(
      state,
      action: PayloadAction<{ id: string; role: string }>
    ) {
      const member = state.members.find(
        (member) => member.id === action.payload.id
      );
      if (member) {
        member.data.role = action.payload.role;
      }
    }
  }
});

export const {
  fetchMembersStart,
  setMembers,
  fetchMembersFailure,
  removeMember,
  changeMemberRole
} = membersSlice.actions;

export default membersSlice.reducer;
