import { styled } from '@mui/system';
import { Box, List, ListItem, StyledComponentProps } from '@mui/material';
import { StyledProps } from '@mui/styles';
import { Theme } from '@mui/material/styles';

interface Props {
  theme: Theme;
  selected: boolean;
}

export const MenuWrapper = styled(Box)(({ theme }) => ({
  '.MuiList-root': {
    padding: theme.spacing(0)
  }
}));

export const StyledListItem = styled(ListItem)(
  ({ theme, ...props }: Props) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px 0px',
    borderRadius: 0,

    borderLeft: props.selected
      ? `4px solid ${theme.palette.primary.main}`
      : 'none',
    backgroundColor: 'transparent',
    color: props.selected
      ? theme.palette.text.primary
      : theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: theme?.heleosColors.primary.white,
      color: theme.palette.common.black,
      cursor: 'pointer'
    }
  })
);
