import React, { useEffect, useRef } from 'react';
import {
  Card,
  CardContent,
  Box,
  Typography,
  Chip,
  useTheme
} from '@mui/material';
import { wsHostname } from 'src/config/api';
import { Icon } from '@iconify/react';
import { Home } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useCheckBulbStatusInOrgQuery } from 'src/@rtk/queries';
import BulbGlow from '../Bulbs/views/glow';

const cssClassesBasedOnBulbState = ['#89BF6C', '#ffff00', '#ec5c5c'];

type Props = {
  org: any
}

export default function OrgCard({ org }: Props) {
  const {
    data: status,
    isLoading: isStatusLoading,
    error: statusError,
    refetch: refetchBulbData,
  } = useCheckBulbStatusInOrgQuery({
    orgId: org?.id
  }, {
    skip: !org?.id,
    pollingInterval: 10000,
    skipPollingIfUnfocused: true
  });

  const wsRef = useRef<WebSocket | null>(null);
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    // Initial data fetch
    if (org?.id) {
      refetchBulbData();
    }

    // WebSocket connection
    wsRef.current = new WebSocket(`${wsHostname}?orgId=${org.id}`);
    wsRef.current.onopen = () => {
      console.log('Connected to bulb server');
    };
    wsRef.current.onerror = (error) => {
      console.error('Error connecting to bulb server', error);
    };
    wsRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      // Handle incoming WebSocket messages here
    };

    // Cleanup function
    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, [org.id]);

  return (
    <Card elevation={3}
      sx={{
        backgroundColor: status?.isFaulty ? theme.colors.error.main : 'white',
      }}
    >
      <CardContent>

        <Box display="flex" alignItems="center" mb={2}>
          <Icon icon="fa6-solid:building-user" style={{ fontSize: 30, color: status?.isFaulty ? theme.heleosColors.primary.white : 'inherit', marginInline: '10px' }} />
          <Typography variant="h6" component="div" sx={{
            color: status?.isFaulty ? theme.heleosColors.primary.white : 'inherit'
          }}>
            {org.name}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Chip
            icon={
              <Home
                sx={{
                  color: status?.isFaulty ? `${theme.heleosColors.primary.white} !important` : 'inherit'
                }}
              />
            }
            label={`Homes: ${org.homeCount !== undefined ? org.homeCount : 'N/A'}`}
            sx={{
              color: status?.isFaulty ? theme.heleosColors.primary.white : 'inherit'
            }}
            variant="outlined"
          />
          <Chip
            icon={
              <BulbGlow
                color={cssClassesBasedOnBulbState[status?.isFaulty ? 2 : 0]}
                isPulsing={false}
                iconSize='25px'
              />
            }
            label={
              <Typography sx={{ padding: '0px !important' }}>
                Bulbs: {org.bulbCount !== undefined ? org.bulbCount : 'N/A'}
              </Typography>
            }
            sx={{
              color: status?.isFaulty ? theme.heleosColors.primary.white : 'inherit',
              py: 1,
              px: 1
            }}
            variant="outlined"
            onClick={() => navigate(`/dashboards/bulbs?orgId=${org.id}`, { replace: true })}
          />
        </Box>
      </CardContent>
    </Card>
  );
}