import React from 'react';
import { animated, useSpring } from 'react-spring';
import { Icon } from '@iconify/react';

interface BulbGlowProps {
    color?: string;
    isPulsing?: boolean;
    isOn?: boolean;
    pulseFrequency?: number; // New prop for controlling pulse frequency
    iconSize?: string;
}

const BulbGlow: React.FC<BulbGlowProps> = ({
    color = '#00ff00',
    isPulsing = true,
    isOn = true,
    pulseFrequency = 1000, // Default frequency is 1000ms (1 second)
    iconSize = '30px',
}) => {
    const styles = useSpring({
        loop: isPulsing,
        from: { opacity: 0 },
        to: [
            { opacity: 1 },
            { opacity: 1 },
            { opacity: 1 },
            { opacity: 0 },
        ],
        config: { duration: pulseFrequency }, // Use the pulseFrequency prop here
    });

    const glowColor = isOn ? color : 'transparent';

    const hexToRgba = (hex: string, alpha: number) => {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };

    return (
        <div
            style={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Icon
                icon="emojione-monotone:light-bulb"
                style={{
                    fontSize: iconSize,
                    color: '#000000',
                }}
            />
            {isOn && (
                <animated.div
                    style={{
                        position: 'absolute',
                        top: '30%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                        backgroundColor: isPulsing
                            ? styles.opacity.to((opacity) => hexToRgba(color, opacity))
                            : glowColor,
                        boxShadow: isPulsing
                            ? styles.opacity.to((opacity) => `0 0 2px 3px ${hexToRgba(color, opacity)}`)
                            : `0 0 2px 3px ${glowColor}`
                    }}
                />
            )}
        </div>
    );
};

export default BulbGlow;