// Import the functions you need from the SDKs you need
import * as firebase from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  connectAuthEmulator,
  initializeAuth,
  indexedDBLocalPersistence,
} from "firebase/auth";
import { firebaseConfig } from "./firebaseConfig";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { Capacitor } from "@capacitor/core";
import { getDatabase } from "firebase/database";
import { getMessaging, onMessage } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Initialize Firebase

// Initialize Firebase
const getFirebaseAuth = () => {
  if (Capacitor.isNativePlatform()) {
    return initializeAuth(firebaseApp, {
      persistence: indexedDBLocalPersistence,
    });
  } else {
    return getAuth(firebaseApp);
  }
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const auth = getFirebaseAuth();

export const firestore =
  process.env.REACT_APP_MODE === "development"
    ? getFirestore(firebaseApp, "develop")
    : getFirestore(firebaseApp);

if (!globalThis.EMULATION && process.env.REACT_APP_MODE === "local") {
  connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true });
  connectFirestoreEmulator(firestore, "localhost", 8080);
  globalThis.EMULATION = true;
}

export const dbRt = getDatabase();
export let messaging;
try {
  messaging = getMessaging(firebaseApp);
} catch (e) {
  console.log("Messaging not supported");
}
