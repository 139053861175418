import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Notification } from 'src/interfaces/notifications';
const initialState = {
  notifications: [] as Notification[]
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    _setNotifications: (state, action: PayloadAction<Notification>) => {
      return {
        ...state,
        notifications: [...state.notifications, action.payload]
      };
    },
    _addNotification: (state, action: PayloadAction<Notification[]>) => {
      let notifications = [...state.notifications];
      action.payload.forEach((notification) => {
        notifications = notifications.filter((n) => n.id !== notification.id);
      });
      notifications = [...action.payload, ...notifications];
      return {
        ...state,
        notifications: notifications
      };
    },
    _resolveNotification: (state, action: PayloadAction<Notification>) => {
      state.notifications = state.notifications.map((notification) => {
        if (notification.id === action.payload.id) {
          notification.resolving = true;
        }
        return notification;
      });
    },
    _deleteNotification: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.id !== action.payload
        )
      };
    },
    _setToggleSingleNotificationLoading: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.map((notification) => {
        if (notification.id === action.payload) {
          notification.loading = !notification.loading;
        }
        return notification;
      });
    },
    _removeNotifications: (state, action) => {
      return {
        ...initialState
      };
    }
  },

});

export const {
  _setNotifications,
  _removeNotifications,
  _resolveNotification,
  _addNotification,
  _deleteNotification,
  _setToggleSingleNotificationLoading,
} = notificationSlice.actions;

export default notificationSlice.reducer;
