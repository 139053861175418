// activitiesSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Home, Room } from '../../../interfaces/homes.interface';
import { Bulb } from '../../../interfaces/bulbs.interface';
import { _clearUser } from '../Users/userSlice';
import { api } from 'src/@rtk/queries';

// Define an initial state

interface HomeState {
  homes: Home[];
}
const initialState: HomeState = {
  homes: []
};

export interface MoveBulbAction {
  homeId: string;
  bulb: Bulb | null;
  sourceRoomId: string;
  targetRoomId: string;
}

// Create a slice
const homeSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    _addHomes: (state: HomeState, action: PayloadAction<Home[]>) => {
      return {
        homes: action.payload
      };
    },
    _removeABulb: (
      state: HomeState,
      action: PayloadAction<{ homeId: string; roomId: string; bulbId: string }>
    ) => {
      return {
        ...state,
        homes: state.homes.map((home) => {
          if (home.id === action.payload.homeId) {
            return {
              ...home,
              rooms: home.rooms?.map((room) => {
                if (room.id === action.payload.roomId) {
                  // Filter out the bulb to be removed
                  const updatedBulbs = room.bulbs?.filter(
                    (bulb) => bulb?.id !== action.payload.bulbId
                  );
                  return {
                    ...room,
                    bulbs: updatedBulbs || [] // Ensure bulbs is an empty array if there are no bulbs left
                  };
                } else {
                  return room; // Return the room unchanged if the IDs don't match
                }
              })
            };
          } else {
            return home; // Return the home unchanged if the IDs don't match
          }
        })
      };
    },

    _addABulb: (state: HomeState, action: PayloadAction<Bulb>) => {
      console.log(action.payload, 'maithandi');
      return {
        ...state,
        homes: state.homes.map((home) => {
          if (home.id === action.payload.homeId) {
            return {
              ...home,
              rooms: home.rooms?.map((room) => {
                if (room.id === action.payload.roomId) {
                  if (room.bulbs && room.bulbs?.length > 0) {
                    return {
                      ...room,
                      bulbs: [
                        ...room.bulbs,
                        { ...action.payload, isMaster: false }
                      ]
                    };
                  } else {
                    return {
                      ...room,
                      bulbs: [{ ...action.payload, isMaster: true }]
                    };
                  }
                } else {
                  return room; // Return the room unchanged if the IDs don't match
                }
              })
            };
          } else {
            return home; // Return the home unchanged if the IDs don't match
          }
        })
      };
    },

    _addBulbsIntoRoom: (state: HomeState, action: PayloadAction<Bulb[]>) => {
      return {
        ...state,
        homes: state.homes.map((home) => {
          const updatedRooms = home.rooms?.map((room) => {
            const bulbsToAdd = action.payload.filter(
              (bulb) => bulb?.roomId === room.id
            );
            if (bulbsToAdd.length > 0) {
              const existingBulbIds = new Set(
                room.bulbs?.map((bulb) => bulb?.id)
              );

              const newBulbs_ = bulbsToAdd.map((bulb) => {
                if (!existingBulbIds.has(bulb?.id)) {
                  if (existingBulbIds.size > 0) {
                    return {
                      ...bulb,
                      isMaster: false
                    };
                  } else {
                    return bulb;
                  }
                }
              });

              const newBulbs = bulbsToAdd.filter(
                (bulb) => !existingBulbIds.has(bulb?.id)
              );
              return {
                ...room,
                bulbs: room.bulbs ? [...room.bulbs, ...newBulbs] : newBulbs
              };
            }
            return room;
          });
          return {
            ...home,
            rooms: updatedRooms
          };
        })
      };
    },

    _moveBulbs: (
      state,
      action: PayloadAction<{
        bulb: Bulb;
        homeId: string;
        sourceRoomId: string;
        targetRoomId: string;
        newMaster?: string;
      }>
    ) => {
      return {
        ...state,
        homes: state.homes.map((home) => {
          if (home.id === action.payload.homeId) {
            return {
              ...home,
              rooms: home.rooms?.map((room) => {
                if (room.id === action.payload.sourceRoomId) {
                  let filteredBulbs: Bulb[] =
                    room.bulbs?.filter(
                      (bulb) => bulb?.id !== action.payload.bulb?.id
                    ) || ([] as Bulb[]);

                  if (action.payload.newMaster !== '') {
                    filteredBulbs = filteredBulbs?.map((bulb) => {
                      console.log(bulb?.id, action.payload.newMaster);
                      if (bulb?.id === action.payload.newMaster) {
                        return {
                          ...bulb,
                          isMaster: true
                        };
                      } else {
                        return {
                          ...bulb,
                          isMaster: false
                        };
                      }
                    });
                  }
                  return {
                    ...room,
                    bulbs: room.bulbs?.filter(
                      (bulb) => bulb?.id !== action.payload.bulb?.id
                    )
                  };
                } else if (room.id === action.payload.targetRoomId) {
                  let newBulbData: Bulb = {
                    ...action.payload.bulb,
                    roomId: room.id,
                    roomLabel: room.label,
                    isMaster:
                      room.bulbs && room.bulbs?.length > 0 ? false : true
                  };
                  return {
                    ...room,
                    bulbs: room.bulbs
                      ? [...room.bulbs, newBulbData]
                      : [newBulbData]
                  };
                } else {
                  return room;
                }
              })
            };
          } else {
            return home;
          }
        })
      };
    },
    _makeMaster: (
      state,
      action: PayloadAction<{ bulbId: string; homeId: string; roomId: string }>
    ) => {
      return {
        homes: state.homes.map((home: Home) => {
          if (home.id === action.payload.homeId) {
            return {
              ...home,
              rooms: home.rooms?.map((room: Room) => {
                if (room.id === action.payload.roomId) {
                  return {
                    ...room,
                    bulbs: room.bulbs?.map((bulb: Bulb) => {
                      if (bulb?.id === action.payload.bulbId) {
                        return {
                          ...bulb,
                          isMaster: true
                        };
                      } else {
                        return {
                          ...bulb,
                          isMaster: false
                        };
                      }
                    })
                  };
                } else {
                  return room;
                }
              })
            };
          } else {
            return home;
          }
        })
      };
    },

    _clearHome: (state, action: PayloadAction<Home>) => {
      return {
        ...initialState
      };
    },
    _clearNewlyAddedTag: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        homes: state.homes.map((home) => {
          if (home.id === action.payload) {
            return {
              ...home,
              newlyAdded: false
            };
          } else {
            return home;
          }
        })
      };
    },

    _deleteHome: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        homes: state.homes.filter((home) => home.id !== action.payload)
      };
    },

    _addRooms: (
      state,
      action: PayloadAction<{
        homeId: string;
        room: any;
        hasExitFromHome?: boolean;
      }>
    ) => {
      const { homeId, room } = action.payload;
      const homes = state.homes.map((home) => {
        if (home.id === homeId) {
          if (home.rooms) {
            return {
              ...home,
              rooms: [...home.rooms, room]
            };
          } else {
            return {
              ...home,
              rooms: [room]
            };
          }
        }
        return home;
      });
      return {
        ...state,
        homes: homes
      };
    },
    _deleteRoom: (
      state,
      action: PayloadAction<{ homeId: string; roomId: string }>
    ) => {
      return {
        ...state,
        homes: state.homes.map((home) => {
          if (home.id === action.payload.homeId) {
            return {
              ...home,
              rooms: home.rooms?.filter(
                (room) => room.id !== action.payload.roomId
              )
            };
          } else {
            return home;
          }
        })
      };
    }
  },

  extraReducers: (builder) => {
    builder.addCase(_clearUser, () => {
      return {
        homes: []
      };
    });
    builder.addMatcher(
      api.endpoints.getAllHomes.matchFulfilled,
      (state, action) => {
        console.log(action.payload, 'payload homes');
        return {
          ...state,
          homes: action.payload.data
        };
      }
    );
    builder.addMatcher(
      api.endpoints.updateRoom.matchFulfilled,
      (state, action) => {
        return {
          ...state,
          homes: state.homes.map((home) => {
            if (home.id === action.payload.data.homeId) {
              return {
                ...home,
                rooms: home.rooms?.map((room) => {
                  if (room.id === action.payload.data.id) {
                    return {
                      ...room,
                      ...action.payload.data
                    };
                  } else {
                    return room;
                  }
                })
              };
            } else {
              return home;
            }
          })
        };
      }
    );
    builder.addMatcher(
      api.endpoints.updateHome.matchFulfilled,
      (state, action) => {
        return {
          ...state,
          homes: state.homes.map((home) => {
            if (home.id === action.payload.data.id) {
              return {
                ...home,
                ...action.payload.data
              };
            } else {
              return home;
            }
          })
        };
      }
    );
  }
});

// Export actions and reducer
export const {
  _clearHome,
  _addHomes,
  _addRooms,
  _addBulbsIntoRoom,
  _moveBulbs,
  _addABulb,
  _makeMaster,
  _removeABulb,
  _clearNewlyAddedTag,
  _deleteHome,
  _deleteRoom
} = homeSlice.actions;
export default homeSlice.reducer;
