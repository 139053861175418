import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userReducer from './reducers/Users/userSlice';

import homeSlice from './reducers/homes/homeSlice';
import authSlice from './reducers/Users/authSlice';
import bulbSlice from './reducers/bulb/bulbSlice';
import notification from './reducers/notifications/notification';

import bulbAction from './reducers/bulb/bulbAction';
import bulbRegistrationSlice from './reducers/bulb/bulbRegistrationSlice';
import snack from './reducers/alerts/snack';
import alertsLayoutSlice from './reducers/layout/alertsLayoutSlice';
import responsiveSlice from './reducers/layout/responsiveSlice';
import alerts from './reducers/notifications/alerts';
import assignBulbs from './reducers/bulb/assign-bulbs';
import membersSlice from './reducers/Users/membersSlice';
import bulbSearch from './reducers/bulb/bulb-search';
import wsNotification from './reducers/notifications/ws-notification';
import { api } from 'src/@rtk/queries';

const store = configureStore({
  reducer: {
    user: userReducer,
    members: membersSlice,
    // Homes
    homes: homeSlice,
    bulb: bulbSlice,
    bulbSearch: bulbSearch,
    bulbAction: bulbAction,
    bulbRegistration: bulbRegistrationSlice,
    auth: authSlice,
    notifications: notification,

    // dialogs
    assignBulb: assignBulbs,
    // Layout
    responsiveLayout: responsiveSlice,
    alertsLayout: alertsLayoutSlice,

    // Alerts
    alerts: alerts,
    snack: snack,
    wsnotification: wsNotification,
    [api.reducerPath]: api.reducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(api.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
