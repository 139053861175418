import React, { useRef, useEffect, useState } from 'react'
import { styled } from '@stitches/react'
import { useTrail, animated } from '@react-spring/web';
import ReactConfetti from 'react-confetti'

// Import your audio file
import loadingSound from 'src/assets/british_grenadiers.mp3'  // Adjust the path as needed

const AppContainer = styled('div', {
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#ffffff',
})

const Container = styled('div', {
  display: 'flex',
  gap: 10,
  marginBottom: 80,
})

const Box = styled('div', {
  position: 'relative',
  height: 50,
  width: 50,
})

const SharedStyles = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  inset: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Helvetica',
  fontWeight: 800,
  backfaceVisibility: 'hidden',
}

const FrontBox = styled(animated.div, {
  ...SharedStyles,
  backgroundColor: '#000000',
  border: 'solid 2px #000000',
  color: '#ffffff',
})

const BackBox = styled(animated.div, {
  ...SharedStyles,
  backgroundColor: '#000000',
  border: 'solid 2px #000000',
  color: '#ffffff',
})

const items = 'HAIL ADMIN!'.split('')

const LoadingScreen = ({ onComplete, duration = 3000 }:{
    onComplete: () => void,
    duration?: number,
}) => {
  const [trail, api] = useTrail(items.length, () => ({
    rotateX: 0,
  }))


  const [ showConfetti, setShowConfetti ] = useState(false);

  const isFlipped = useRef(false)
  const audioRef = useRef(new Audio(loadingSound))

  const handleFlip = () => {
    if (!isFlipped.current) {
      api.start({
        rotateX: 180,
      })
      isFlipped.current = true
    }
  }

  useEffect(() => {

    setShowConfetti(true);
    // Start playing the audio
    audioRef.current.play()

    // Automatically flip after a short delay
    const flipTimeout = setTimeout(handleFlip, 500)
    
    // Exit the component and stop the audio after the specified duration
    const exitTimeout = setTimeout(() => {
      audioRef.current.pause()
      audioRef.current.currentTime = 0
      onComplete()
    }, duration)

    return () => {
      clearTimeout(flipTimeout)
      clearTimeout(exitTimeout)
      audioRef.current.pause()
      audioRef.current.currentTime = 0
    }
  }, [duration, onComplete])

  return (
    <AppContainer>

{showConfetti && (
        <ReactConfetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          numberOfPieces={200}
          gravity={0.2}
        />
      )}
      <Container>
        {trail.map(({ rotateX }, i) => (
          <Box key={i}>
            <FrontBox
              style={{
                transform: rotateX.to(val => `perspective(600px) rotateX(${val}deg)`),
                transformStyle: 'preserve-3d',
              }}>
              {'?'}
            </FrontBox>
            <BackBox
              style={{
                transform: rotateX.to(val => `perspective(600px) rotateX(${180 - val}deg)`),
                transformStyle: 'preserve-3d',
              }}>
              {items[i]}
            </BackBox>
          </Box>
        ))}
      </Container>
    </AppContainer>
  )
}

export default LoadingScreen