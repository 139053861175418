import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  signInWithEmailAndPassword,
  onAuthStateChanged
} from 'firebase/auth';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { VisibilityOff, VisibilityRounded } from '@mui/icons-material';
import { auth } from 'src/config/firebase';
import AuthWrapper from './components/wrapper';
import LoadingButton from '@mui/lab/LoadingButton';

const useStyles = makeStyles((theme) => ({
  gridItem: {
    width: '70%',
    '@media (max-width: 500px)': {
      width: '100%'
    }
  }
}));

const CustomTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
      WebkitTextFillColor: 'inherit'
    }
  }
});

function Login() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const classes = useStyles();

  const { control, handleSubmit, watch, formState: { errors } } = useForm();



  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await signInWithEmailAndPassword(auth, data.email, data.password);
      // The navigation will be handled by the onAuthStateChanged listener
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const password = watch('password');

  return (
    <AuthWrapper>
      <Box
        component="form"
        noValidate={false}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          mt: 3,
          margin: 0,
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Grid container spacing={1} p={2}>
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: 'Email is required',
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: 'Invalid email address'
                }
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  type="email"
                  fullWidth
                  autoFocus
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{ required: 'Password is required' }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  fullWidth
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <VisibilityRounded />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Typography color="error">{error}</Typography>
            </Grid>
          )}
          <Grid item xs={6} m={'auto'} p={0}>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={loading}
              fullWidth
              sx={{ color: 'white' }}
            >
              Login
            </LoadingButton>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Link href="/signup" variant="body2">
              Don't have an account? Sign up
            </Link>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Link href="/privacy-policy" variant="body2">
              Privacy Policy
            </Link>
          </Grid>
        </Grid>
      </Box>
    </AuthWrapper>
  );
}

export default Login;