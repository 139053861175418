import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AlertLayoutState, AlertsTab } from 'src/redux/types/alerts';

const initialState: AlertLayoutState = {
  activeAlertsTab: null,
  alertsModalActive: false
};

const alertsLayoutSlice = createSlice({
  name: 'alertsLayout',
  initialState,
  reducers: {
    _setAlertsModalActive: (
      state: AlertLayoutState,
      action: PayloadAction<boolean>
    ): AlertLayoutState => {
      return {
        ...state,
        alertsModalActive: action.payload
      };
    },
    _setActiveAlertsTab: (
      state: AlertLayoutState,
      action: PayloadAction<AlertsTab>
    ): AlertLayoutState => {
      return {
        ...state,
        activeAlertsTab: action.payload
      };
    }
  }
});

export const { _setAlertsModalActive, _setActiveAlertsTab } =
  alertsLayoutSlice.actions;
export default alertsLayoutSlice.reducer;
