import React, { useEffect, useState } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    Button, Typography, Grid, Paper, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow,
    ThemeProvider, createTheme, CssBaseline
} from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useGetBulbDataWithRequestCountQuery } from 'src/@rtk/queries';



const BulbDetailsDialog = ({ open, onClose, bulbData }) => {
    const [requestCounts, setRequestCounts] = useState(null);


    const {
        data: data,
        isLoading: isRequestCountsLoading,
        error: requestCountsError,
    } = useGetBulbDataWithRequestCountQuery(
        { bulbId: bulbData?.id },
        {
            skip: !bulbData?.id,
        }
    )


    useEffect(() => {
        if (data?.data?.requestCounts) {
            setRequestCounts(data.data.requestCounts)
        }
    }, [data])

    // Transform requestCounts data for the chart
    const chartData = Object.entries(requestCounts || {}).map(([time, count]) => ({
        time,
        count
    }));

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Bulb Details: {bulbData?.id}</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '16px' }}>
                            <Typography variant="h6" gutterBottom>General Information</Typography>
                            <TableContainer>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Home</TableCell>
                                            <TableCell>{bulbData?.homeLabel}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Room</TableCell>
                                            <TableCell>{bulbData?.roomLabel}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Organization</TableCell>
                                            <TableCell>{bulbData?.orgName}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Firmware</TableCell>
                                            <TableCell>{bulbData?.firmware}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Target Firmware</TableCell>
                                            <TableCell>{bulbData?.targetFirmware}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '16px' }}>
                            <Typography variant="h6" gutterBottom>Status Information</Typography>
                            <TableContainer>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Is Master</TableCell>
                                            <TableCell>{bulbData?.isMaster ? 'Yes' : 'No'}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">BLE State</TableCell>
                                            <TableCell>{bulbData?.bleState ? 'On' : 'Off'}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">BLE Paired</TableCell>
                                            <TableCell>{bulbData?.bleIsPaired ? 'Yes' : 'No'}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">LED Fault</TableCell>
                                            <TableCell>{bulbData?.ledFault ? 'Yes' : 'No'}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Mode</TableCell>
                                            <TableCell>{bulbData?.mode}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={3} style={{ padding: '16px' }}>
                            <Typography variant="h6" gutterBottom>Request Counts (Last 24 Hours)</Typography>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart data={chartData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="time" />
                                    <YAxis />
                                    <Tooltip />
                                    <Line type="monotone" dataKey="count" stroke="#8884d8" />
                                </LineChart>
                            </ResponsiveContainer>
                        </Paper>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default BulbDetailsDialog;