import { PayloadAction, createSlice } from '@reduxjs/toolkit';
interface LayoutState {
  open: boolean;
}

const initialState: LayoutState = {
  open: false
};

const responsiveSlice = createSlice({
  name: 'responsiveSlice',
  initialState,
  reducers: {
    _toggleSideDrawer: (state: LayoutState, action: PayloadAction<boolean>) => {
      return {
        ...state,
        open: action.payload
      };
    }
  }
});

export const { _toggleSideDrawer } = responsiveSlice.actions;

export default responsiveSlice.reducer;
