import { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';

import {
  Box,
  Drawer,
  alpha,
  styled,
  Divider,
  useTheme,
  Button,
  lighten,
  darken,
  Tooltip,
  useMediaQuery
} from '@mui/material';

import SidebarMenu from './SidebarMenu';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 68px;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isMediumScreen = useMediaQuery(
    '(min-width:601px) and (max-width:960px)'
  );
  const isLargeScreen = useMediaQuery('(min-width:961px)');
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(min-width: 601px) and (max-width: 1024px)');

  return (
    <>
      <Box
        sx={{
          display: isTablet || isMobile ? 'none' : 'block',
          width: isSmallScreen ? 0 : theme.sidebar.width,
          position: 'fixed',
          maxHeight: `calc(100vh - ${theme.header.height})`,
          height: `calc(100vh - ${theme.header.height} - 2px)`,
          left: 0,
          top: `calc(${theme.header.height} + 2px)`,
          background: theme.palette.background.paper,
          boxShadow: theme.sidebar.boxShadow,
          zIndex: !isTablet || !isMobile ? 1000 : 100
        }}
      >
        <SidebarMenu />
        <Divider
          sx={{
            background: theme.colors.alpha.trueWhite[10]
          }}
        />
      </Box>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <Box
          sx={{
            width: `${theme.sidebar.width}`,
            minWidth: `${theme.sidebar.width}`,
            color: `${theme.colors.alpha.trueWhite[70]}`,
            position: 'relative',
            zIndex: 7,
            height: `100%`,
            paddingBottom: `68px`
          }}
        >
          <Scrollbar>
            <Divider
              sx={{
                mt: theme.spacing(3),
                mx: theme.spacing(2),
                background: theme.colors.alpha.trueWhite[10]
              }}
            />
            <SidebarMenu />
          </Scrollbar>
        </Box>
      </Drawer>
    </>
  );
}

export default Sidebar;
