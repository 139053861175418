import { useContext } from 'react';
import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem,
  Typography,
  useTheme
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';

// Import only the icons we need
import BrightnessLowTwoToneIcon from '@mui/icons-material/BrightnessLowTwoTone';
import MmsTwoToneIcon from '@mui/icons-material/MmsTwoTone';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import { MenuWrapper, StyledListItem } from './styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';




// Hardcoded list of dashboard menu items
const dashboardMenuItems = [
  {
    path: '/dashboards/orgs',
    icon: 'fa6-solid:building-user',
    label: 'Organisations'
  },
  {
    path: '/dashboards/bulbs',
    icon: 'material-symbols-light:home-outline',
    label: 'Bulbs'
  },
];

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);

  const navigate = useNavigate();
  const theme = useTheme();

  const handleMenuClick = (path: any) => {
    navigate(path);
  };

  return (
    <MenuWrapper>
      <List component="div">
        {dashboardMenuItems.map((item, index) => (
          <StyledListItem
            theme={theme}
            key={index}
            selected={window.location.pathname === item.path}
            onClick={() => handleMenuClick(item.path)}
          >
            {item.label === 'Alerts' ? (
              <Icon icon={item.icon} style={{ fontSize: 20 }} />
            ) : (
              <Icon icon={item.icon} style={{ fontSize: 20 }} />
            )}
            <Typography sx={{ fontSize: 14 }}>{item.label}</Typography>
          </StyledListItem>
        ))}
      </List>
    </MenuWrapper>
  );
}

export default SidebarMenu;