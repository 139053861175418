import React from 'react';
import { Grid, useTheme, useMediaQuery, Box, Paper } from '@mui/material';
import { useParams } from 'react-router';
import logoWhite from 'src/assets/heleos-logo-whiteout-rgb@4x.png';
import logo from 'src/assets/heleos.png';

interface AuthWrapperProps {
  children: React.ReactNode;
}

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const params = useParams();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{

        minHeight: '100vh',
        width: '100vw',
        overflow: 'auto',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: { xs: '100%', sm: '100%', md: '90%', lg: '80%' },
          maxWidth: '100vw',
          overflow: 'hidden',
          display: 'flex',
          height: { xs: 'auto', sm: 'auto', md: '90vh', lg: '90vh' },
          marginBlock: 'auto',
          minHeight: { xs: '100vh', sm: '100vh', md: 'auto' },
          borderRadius: '0px !important',
          flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row' },
        }}
      >
        <Grid
          container
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column !important',
            justifyContent: 'flex-start',
            alignItems: 'center',
            p: 3,
            height: '100%',
            overflow: 'auto',
            marginBlock: 'auto'
          }}
        >
          <Box
            sx={{
              display: 'none',
              [theme.breakpoints.down('md')]: {
                display: 'flex',
                flexDirection: 'column',
              },
              justifyContent: 'center',
              alignItems: 'center',
              mb: 4,
              width: '100%'
            }}
          >
            <img
              src={logo}
              alt="Circadacare Logo"
              style={{ maxWidth: '200px', height: 'auto' }}
            />
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexGrow: 1,
              overflow: 'auto'
            }}
          >
            {children}
          </Box>
        </Grid>

        {isDesktop && (
          <Grid
            item
            md={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: theme.palette.primary.main,
              p: 3,
              height: '100%'
            }}
          >
            <img
              src={logoWhite}
              alt="Circadacare Logo"
              style={{ maxWidth: '300px', height: 'auto' }}
            />
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};

export default AuthWrapper;