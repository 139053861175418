import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BleDevice, BleClient } from '@capacitor-community/bluetooth-le';

export enum RegistrationSteps {
  BLUETOOTH = 0,
  WIFI = 1,
  REGISTER_TO_ORG = 2,
  // HOME = 3,
  DONE = 3
}

export const bluetoothUUIDs = {
  bulbService: '7e2a0001-b5a3-f393-e0a9-e50e24dcca9e',
  networkCredentialsCharacteristic: '7e2a0002-b5a3-f393-e0a9-e50e24dcca9e',
  pulseGreenCharacteristic: '7e2a0003-b5a3-f393-e0a9-e50e24dcca9e',
  setupCompleteCharacteristic: '7e2a0004-b5a3-f393-e0a9-e50e24dcca9e',
  networkListCharacteristic: '7e2a0005-b5a3-f393-e0a9-e50e24dcca9e',
  activeNetworkCharacteristic: '7e2a0006-b5a3-f393-e0a9-e50e24dcca9e',
  userTokenCharacteristic: '7e2a0007-b5a3-f393-e0a9-e50e24dcca9e'
};

interface RegistrationState {
  currentStep: RegistrationSteps;
}

const initialState: RegistrationState = {
  currentStep: RegistrationSteps.BLUETOOTH
};

const bulbRegistrationSlice = createSlice({
  name: 'bulbRegistration',
  initialState,
  reducers: {
    _resetSteps: (state) => {
      console.log('resetting steps!');
      state.currentStep = RegistrationSteps.BLUETOOTH;
    },
    _incrementStep: (state) => {
      if (state.currentStep < RegistrationSteps.DONE - 1) {
        state.currentStep++;
      }
    }
  }
});

export const { _resetSteps, _incrementStep } = bulbRegistrationSlice.actions;
export default bulbRegistrationSlice.reducer;
