import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Bulb } from 'src/interfaces/bulbs.interface';

interface BulbState {
  selectedBulb: Bulb | null;
}

const initialState: BulbState = {
  selectedBulb: null
};

const bulbSlice = createSlice({
  name: 'bulb',
  initialState,
  reducers: {
    selectBulb: (state, action: PayloadAction<Bulb>) => {
      state.selectedBulb = action.payload;
    }
  }
});

export const { selectBulb } = bulbSlice.actions;
export default bulbSlice.reducer;
