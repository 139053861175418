// authSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { User } from 'firebase/auth';

interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  isLoading: boolean;
  token: string | null;
  authErrorMessage: string | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  isLoading: false,
  token: null,
  authErrorMessage: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<null>) => {
      state.isAuthenticated = !!action.payload;
      state.user = action.payload;
      state.isLoading = false;
    },
    clearUser: (state, action: PayloadAction<null>) => {
      state.isAuthenticated = false;
      state.user = null;
      state.isLoading = false;
    },
    _setAuthLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    _setAuthErrorMessage: (state, action: PayloadAction<string | null>) => {
      state.authErrorMessage = action.payload;
    }
  }
});

export const { setUser, clearUser, _setAuthLoading, _setAuthErrorMessage } =
  authSlice.actions;

export default authSlice.reducer;
