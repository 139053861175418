import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AssignBulbsState {
  isOpen: boolean;
  selectedBulbs: string[];
}

const initialState: AssignBulbsState = {
  isOpen: false,
  selectedBulbs: []
};

const assignBulbsSlice = createSlice({
  name: 'assignBulbs',
  initialState,
  reducers: {
    _setOpenAssignBulbDBox: (
      state: AssignBulbsState,
      action: PayloadAction<AssignBulbsState>
    ) => {
      state.isOpen = true;
      state.selectedBulbs = action.payload.selectedBulbs;
    },
    _setCloseAssignBulbDBox: (state) => {
      state.isOpen = false;
      state.selectedBulbs = [];
    }
  }
});

export const { _setOpenAssignBulbDBox, _setCloseAssignBulbDBox } =
  assignBulbsSlice.actions;

export default assignBulbsSlice.reducer;
