import { createSlice } from "@reduxjs/toolkit"
import { Notification } from "src/interfaces/notifications";

const initialState: { alerts: Notification[] } = {
    alerts: []
}


const alertSlice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        _setAlerts: (state, action) => {
            return {
                ...state,
                alerts: action.payload
            };
        },
        _removeAlerts: (state, action) => {
            let alerts = [...state.alerts].filter(
                (alert) => alert.id !== action.payload
            );
            return {
                ...state,
                alerts: alerts
            };
        }
    },

});



export const { _setAlerts, _removeAlerts } = alertSlice.actions;
export default alertSlice.reducer;